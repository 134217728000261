:root {
  --primary: #000000;
  --primary-text: #1a1a1a;
  --secondary: #000000;
  --secondary-text: #ffffff;
  --accent: #505050;
  --accent-text: #ffffff;
}

.expired-notice {
  
  text-align: center;
  padding: 2rem;
  border: 6px solid #000000;
  border-radius: 0.25rem;
  margin: 0.5rem;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.expired-notice > span {
  
  font-size: 2rem;
  font-weight: normal;
  color: rgb(255, 255, 255);
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 10px solid #000000;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  animation: blinker 1s linear infinite;
  color: #ecc3c3;
  font-size: 1 rem;
  font-weight: bold;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

button:disabled {
  background: #cccccc;
  color : #666666
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}